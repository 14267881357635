import React, { useEffect, useState, lazy, Suspense } from "react";
import { Switch, withRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators as bAC } from "redux";

import PreAuthRouter from "./preAuth";
import AuthRouter from "./auth";

import { isLoggedIn } from "./../redux/user/actions";

import Loading from "./../pages/loading";
const NotFound = lazy(() => import("../pages/notFound"));
const Login = lazy(() => import("../pages/login"));
const Home = lazy(() => import("../pages/home"));
const ActiveMediaScreens = lazy(() => import("../pages/activeMediaScreens"));
const Dnd = lazy(() => import("../pages/dnd"));

const ViewerC = ({ isLoggedIn }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        isLoggedIn(() => {
            setLoading(false);
        });
    }, [isLoggedIn]);

    return loading ? (
        <Loading />
    ) : (
        <div id="main-viewer">
            <div className="flex-main">
                <div className="pages">
                    <Suspense fallback={<Loading open />}>
                        <Switch>
                            <PreAuthRouter
                                exact
                                path="/login"
                                component={Login}
                            />
                            <Route
                                exact
                                path="/activeMediaScreens/:mediaScreenId"
                                component={ActiveMediaScreens}
                            />
                            <Route exact path="/dnd" component={Dnd} />
                            <AuthRouter exact path="/" component={Home} />
                            <AuthRouter
                                exact
                                path="/mediaScreens/:mediaScreenId?"
                                component={Home}
                            />
                            <Route component={NotFound} />
                        </Switch>
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

let mDTP = (dispatch) => bAC({ isLoggedIn }, dispatch);
export default withRouter(connect(null, mDTP)(ViewerC));
