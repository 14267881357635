import { combineReducers } from "redux";
import userReducer from "./user";
import mediaScreensReducer from "./mediaScreens";
import screensReducer from "./screens";

const allReducers = combineReducers({
    user: userReducer,
    mediaScreens: mediaScreensReducer,
    screens: screensReducer,
});

export default allReducers;
