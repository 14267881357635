import React from "react";
import Logo from "./logo";
import { Loader } from "react-feather";
import "./style.scss";

const Loading = (props) => {
    return (
        <div className={"loading-page page " + (props.dark && "dark")}>
            <Logo />
            <Loader className="loading-icon" />
        </div>
    );
};

export default Loading;
