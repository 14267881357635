import { types } from "../types";

export default function MediaScreensReducer(state = [], action) {
    switch (action.type) {
        case types.APPLY_SCREENS:
            state = action.payload;
            break;
        case types.ADD_SCREEN:
            state = [...state, action.payload.screen];
            break;
        case types.EDIT_SCREEN:
            state = state.map((sc) =>
                sc._id === action.payload._id
                    ? { ...sc, ...action.payload }
                    : sc
            );
            break;
        case types.DELETE_SCREEN:
            state = state.map((a) =>
                a._id === action.payload.screenId
                    ? {
                          ...a,
                          shared: a.shared.filter(
                              (sh) => sh !== action.payload.mediaScreenId
                          ),
                      }
                    : a
            );
            break;
        case types.SHARE_SCREEN:
            state = state.map((sc) =>
                sc._id === action.payload._id
                    ? { ...sc, shared: action.payload.shared }
                    : sc
            );
            break;
        case types.LOGGED_OUT:
            state = [];
            break;
        default:
            break;
    }
    return state;
}
