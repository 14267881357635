import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PreAuthRouter = ({ user, exact, path, component, location }) => {
    if (!user.state)
        return <Route exact={exact} path={path} component={component} />;
    return <Redirect to={{ pathname: "/", state: { from: location } }} />;
};

let mSTP = (state) => ({ user: state.user });
export default connect(mSTP)(PreAuthRouter);
