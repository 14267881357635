import { types } from "../types";

export default function MediaScreensReducer(state = [], action) {
    switch (action.type) {
        case types.APPLY_MEDIA_SCREENS:
            state = action.payload;
            break;
        case types.ADD_MEDIA_SCREEN:
            state = [...state, action.payload];
            break;
        case types.ADD_SCREEN:
            state = state.map((a) => {
                if (a._id === action.payload.mediaScreenId) {
                    return {
                        ...a,
                        screens: [...a.screens, action.payload.screen._id],
                    };
                } else return a;
            });
            break;
        case types.DRAGGED_SCREEN:
            console.log(action.payload);
            let ms = state.find(
                (ms1) => ms1._id === action.payload.mediaScreenId
            );
            let sIndex = action.payload.s;
            let dIndex = action.payload.d;
            let screen = ms.screens.splice(sIndex, 1);
            ms.screens.splice(dIndex, 0, screen[0]);

            state = state.map((ms2) => (ms2._id === ms._id ? ms : ms2));
            break;

        case types.DELETE_SCREEN:
            state = state.map((a) => {
                if (
                    a._id === action.payload.mediaScreenId ||
                    action.payload.allShared
                ) {
                    return {
                        ...a,
                        screens: a.screens.filter(
                            (screen) => screen !== action.payload.screenId
                        ),
                    };
                } else return a;
            });
            break;
        case types.SHARE_SCREEN:
            state = state.map((a) => {
                let screens = a.screens;
                if (action.payload.shared.includes(a._id)) {
                    if (!screens.includes(action.payload._id))
                        screens.push(action.payload._id);
                } else
                    screens = screens.filter((sc) => sc !== action.payload._id);
                a.screens = screens;
                return a;
            });
            break;
        case types.DELETE_MEDIA_SCREEN:
            state = state.filter((a) => a._id !== action.payload);
            break;
        case types.LOGGED_OUT:
            state = [];
            break;
        default:
            break;
    }
    return state;
}
