import { types } from "../types";

export default function userReducer(state = {}, action) {
    switch (action.type) {
        case types.LOGGED_OUT:
            state = {};
            break;
        case types.LOGGED_IN:
            state = { ...action.payload, state: "loggedIn" };
            break;
        default:
            break;
    }
    return state;
}
