import React from "react";
import logoImage from "./../../../assets/images/jp.png";
import "./style.scss";

const Logo = () => {
    return (
        <div className={"logo-wrapper"}>
            <img alt="logo" src={logoImage} className="logo-image" />
            <div className="logo-text">
                Media<span className="logo-text-2">Screens</span>
            </div>
        </div>
    );
};

export default Logo;
