export const types = {
    LOGGED_IN: "loggedIn",
    LOGGED_OUT: "loggedOut",

    APPLY_MEDIA_SCREENS: "applyMediaScreens",
    APPLY_SCREENS: "applyScreens",
    ADD_MEDIA_SCREEN: "addMediaScreen",
    ADD_SCREEN: "addScreen",
    EDIT_MEDIA_SCREEN: "editMediaScreen",
    EDIT_SCREEN: "editScreen",
    DELETE_MEDIA_SCREEN: "deleteMediaScreen",
    DELETE_SCREEN: "deleteScreen",
    SHARE_SCREEN: "shareScreen",
    DRAGGED_SCREEN: "draggedScreen",
};
