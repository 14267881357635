import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./createStore";

import MainRouter from "./routers";

require("./sass/main.scss");
require("./sass/inputs.scss");
require("./sass/buttons.scss");
require("./sass/tables.scss");
require("./sass/animate.scss");

const app = document.getElementById("root");

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Provider store={store}>
                    <MainRouter />
                </Provider>
            </BrowserRouter>
        );
    }
}

ReactDOM.render(<App></App>, app);
