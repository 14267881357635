import axios from "axios";
import { types } from "./../types";
import { network } from "../../global.js";

export const isLoggedIn = (callback) => {
    const req = axios.get(`${network}/auth/isLoggedIn`, {
        withCredentials: true,
    });
    return (dispatch) => {
        req.then(({ data }) => {
            if (data.state) {
                dispatch({ type: types.LOGGED_IN, payload: data.user });
            } else {
                dispatch({ type: types.LOGGED_OUT, payload: null });
            }
            callback();
        }).catch((err) => {
            console.log(err.message);
        });
    };
};

export const login = (postdata, callback) => {
    const req = axios.post(network + "/auth/login", postdata, {
        withCredentials: true,
    });
    return (dispatch) => {
        req.then(({ data }) => {
            if (data.state) {
                dispatch({ type: types.LOGGED_IN, payload: data.user });
            }
            callback(data.message);
        }).catch(function (error) {
            console.log("ERRR", error);
            callback("Please check your login credentials.");
        });
    };
};

export const logout = (postdata) => {
    const req = axios.get(network + "/auth/logout", { withCredentials: true });
    return (dispatch) => {
        req.then(() => {
            dispatch({ type: types.LOGGED_OUT, payload: {} });
        }).catch((err) => {});
    };
};
